<template>
  <div class="home">
   <div class="hometop">
     <div class="hometop-h1">
        <div class="hometop-h1-left">
          <img src="@/assets/svg/u43104.svg" alt="">
          <span>工单执行进度看板</span>
        </div>
        <div class="hometop-h1-right">
          <div class="time">
            <div class="timeleft">
              <img  src="@/assets/svg/u43102.svg" alt="">
            </div>
            
            <img src="@/assets/svg/u43103.svg" alt="">

            <div class="djs">{{ currentTime }}</div>
          </div>
      
         <div class="line">
            <img src="@/assets/svg/u43101p001.svg" alt="">
         </div>

          <div class="heng">
           
            <img src="@/assets/svg/u43105.svg" alt="">
            <img src="@/assets/svg/u43105.svg" alt="">
            <img src="@/assets/svg/u43105.svg" alt="">
            <div class="hengimg">
              <img src="@/assets/svg/u43108.svg" alt="">
            </div>
            
          
          </div>
        </div>
     </div>
     <div class="hometop-h2">
       <img src="@/assets/svg/u43100.svg" alt="">
     </div>
     <!-- <img src="@/assets/svg/u43109.svg" alt=""> -->
   </div>
   
   <div class="homemain">
    <el-table 
    :data="tableData" 
    style="width: 100%" 
    :stripe="true" 
    :fit="true"
    header-cell-class-name="baitablehearer"
    row-class-name="baitablerow"
    cell-class-name="baitablecell"
    >
    <el-table-column prop="sn" label="生产编号" :width="220">
      <template #default="{row}">
        {{ row?.order_info?.sn || '' }}
      </template>
    </el-table-column>
    <el-table-column prop="client_name" label="客户名称" :width="220"/>
    <el-table-column prop="name" label="方案名称" :width="220">
      <template #default="{row}">
        {{ row?.order_info?.name || '' }}
      </template>
    </el-table-column>
    <el-table-column prop="give_time" label="交付时间" :width="140">
      <template #default="{row}">
        {{ row?.order_info?.give_time || '' }}
      </template>
    </el-table-column>
    <el-table-column prop="work_order_info" label="生产进度" >
      <template #default="{row}">
        <el-steps :active="getProcess(row)" align-center>
          <el-step v-for="(ite,kk) in row.gxArr" :key="kk" :title="ite.name" />
        </el-steps>
      </template>
      
    </el-table-column>
    <!-- <el-table-column prop="company_name" label="生产工厂">
      <template #default="{row}">
        {{ row?.company_info?.name || '' }}
      </template>
    </el-table-column>
    <el-table-column prop="room_name" label="生产车间">
      <template #default="{row}">
        {{ row?.room_info?.name || '' }}
      </template>
    </el-table-column> -->
    <el-table-column prop="line_name" label="生产线" :width="200">
      <template #default="{row}">
        {{ row?.line_info?.name || '' }}
      </template>
    </el-table-column>
    <el-table-column prop="route_name" label="生产工序" :width="200">
      <template #default="{row}">
        {{ row?.route_info?.name || '' }}
      </template>
    </el-table-column>
    <!-- <el-table-column prop="hnum" label="产品数量"  />
    <el-table-column prop="bhnum" label="报工数量"  /> -->
  </el-table>
   </div>
  </div>
</template>

<script lang="ts" setup>
// @ts-nocheck
import {onMounted,ref,onUnmounted} from 'vue';
import { useRoute } from 'vue-router';
import {TaskList} from '@/api/index'

const route = useRoute();
const tableData = ref([])

const getProcess=(record:any)=>{
  let gxcurrent = 0
  record.gxArr.forEach(gx=>{
    if(gx.gx_status===3){
      gxcurrent = gx.sort
    }
  })
  return gxcurrent
}

const fetchData = () => {
  // 100407
  TaskList().then(res => {
    // console.log(res)
    tableData.value = res.data.map(ite => {
      if(!ite.work_order_info){
        ite['gxArr'] = []
      }else{
        ite['gxArr'] = ite.work_order_info.gx.map((ite:any) => {
          return {
            id: ite.gx_id,
            sort: ite.sort,
            sn: ite.gx_info.sn,
            name: ite.gx_info.name,
            gx_status: ite.gx_status,
            gx_id: ite.gx_id,
          }
         
        }).sort((a,b)=> a.sort - b.sort)
      }
      return ite;
    })
  }).catch(err=>{
    ElMessage.error(err.msg||err)
  })
}

const timer = ref(null);
const formatTime=() => {
  const date = new Date();
  const y = date.getFullYear();
  const m = date.getMonth()<9?`0${date.getMonth()+1}`: String(date.getMonth()+1);
  const d = date.getDate() < 10 ? `0${date.getDate()}`: String(date.getDate());
  
  return `${y}-${m}-${d} ${date.toLocaleTimeString()}`;
}
const currentTime = ref(formatTime());

onMounted(()=>{
  // if(route.query.id&&Number(route.query.type)===1){
    fetchData();
  // }
  
  timer.value = setInterval(()=>{
    currentTime.value = formatTime();
  }, 1000);
})

onUnmounted(()=>{
  clearInterval(timer.value)
})
</script>

<style lang="less">
.home {
  width: 100%;
  padding: 20px 20px;
}
.hometop {
  width: 100%;
  // height: 100px;
  // background: pink;
  &-h1 {
    width: 100%;
    // height: 90px;
    // background: plum;
    display: flex;
    &-left {
      flex-shrink: 0;
      position: relative;
      margin-top: 5px;
      span {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        margin: auto;
        font-size: 35px;
        font-weight: bold;
        letter-spacing: 20px;
        text-align: center;
        line-height: 80px;
        color: #12E4E8;
      }
    }
    &-right {
      flex: 1;
      position: relative;
      .time {
        width: 100%;
        display: flex;
        
        .timeleft {
          flex: 1;
          margin-bottom: 10px;
          position: relative;
          img {
            width: 100%;
            position: absolute;
            bottom: 0;
            vertical-align: bottom;
          }
        }
        
        img:nth-child(2){
          flex-shrink: 0;
          margin-left: 10px;
        }
        .djs {
          position: absolute;
          top: 0;
          right: 40px;
          font-size: 20px;
          text-align: center;
          line-height: 36px;
          color: #12E4E8;
        }
      }
      .line {
        position: absolute;
        text-align: right;
        top: 22px;
        right: 19px;
        img {
          width: 100%;
        }
      }
      .heng {
        margin-top: 10px;
        display: flex;
        justify-content: flex-end;
        img:nth-child(1),img:nth-child(2),img:nth-child(3) {
          flex-shrink: 0;
          height: 39px;
          margin-right: -25px;
        }
        .hengimg {
          flex: 1;
          img {
            width: 100%;
            height: 39px;
          }
        }
        
      }
    }
  }
  &-h2 {
    width: 100%;
    text-align: right;
    img {
      width: 100%;
      margin-top: -20px;
    }
  }
}

.homemain {
  width: 100%;

  // background: pink;
  margin-top: 20px;
  border: 3px solid #3293DF;
  padding: 10px;
}

.el-table {
  --el-table-border-color: transparent !important;
}
.baitablehearer {
  background-color: #005EA9 !important;
  color: white !important;
  text-align: center !important;
  border-right: 1px solid #031958 !important;
}
.baitablehearer:last-child {
  border-right: none !important;
}

.el-table__body .baitablerow:nth-child(odd) {
  .baitablecell {
    background-color: #031958 !important;
    color: white !important;
    text-align: center;
  }
  
}
.el-table__body .baitablerow:nth-child(even) {
  .baitablecell {
    background-color: #04335F !important;
    color: white !important;
    text-align: center;
  }
}

.el-step__title.is-process {
  color: white !important;
}
.el-step__head.is-process {
  border-color:#8d8e90 !important;
  color: #8d8e90 !important;
  // background-color: #8d8e90 !important;
}
.el-table__empty-block {
  background: #031958;
}
</style>
